(function(){

	var searchIndex = null;
	var searchUI = document.querySelector('.search-ui');
	var resultsUI = document.querySelector('.search-results');
	var searchInput = document.querySelector('#search-str');
	var site = searchInput.dataset.site;
	var searchToggles = document.querySelectorAll('.search-toggle');
	var searchIcon = document.querySelector('.search-ui .input-group-text');
	var clearBtn = document.querySelector('#btn-search-clear');
	
	// toggle visibility
	var toggleSearch = function(e) {
		e.preventDefault();
		
		if (hasClass(searchUI, 'show')) {
			removeClass(searchUI, 'show');
		} else {
			addClass(searchUI, 'show');
			
			setTimeout(function(){ searchInput.focus() }, 0);
		}
	}
	for(var i = 0; i < searchToggles.length; i++) {
		searchToggles[i].addEventListener('click', function(e) {
			toggleSearch(e);
		});
	}
	
	// clear the current search
	var clearSearchInput = function() {
		searchInput.value = '';
		
		// hide clear button and show the search icon
		removeClass(searchIcon, 'd-none');
		addClass(clearBtn, 'd-none');
	}
	
	// clear the current results
	var clearResults = function(){
		while (resultsUI.firstChild) {
			resultsUI.removeChild(resultsUI.firstChild);
		}
		
		// hide results parent
		resultsUI.classList.add('invisible');
	}
	
	// search and display
	var find = function(str) {
	
		str = str.toLowerCase();
		
		// look for matches in the search JSON
		var results = [];
		for(var item in searchIndex ) {
			var found = searchIndex[item].text.indexOf(str);
			if(found != -1 && searchIndex[item].site === site) {
				results.push(searchIndex[item]);
			}
		}
		
		// build and insert the new result entries
		clearResults();
		resultsUI.classList.remove('invisible');
		for(var item in results) {
			var title = results[item].title.replace(' | Coagadex® Coagulation Factor X (Human)', '');
			var listItem = document.createElement('li');
			var link = document.createElement('a');
			var desc = document.createElement('div');
			var more = document.createElement('a');
			link.textContent = title;
			link.setAttribute('href', results[item].url);
			addClass(link, 'font-weight-bold');
			desc.textContent = results[item].desc + ' ';
			more.setAttribute('href', results[item].url);
			more.textContent = 'Read more'; 
			desc.appendChild(more);
			listItem.appendChild(link);
			listItem.appendChild(desc);
			resultsUI.appendChild(listItem);
		}
	}
	
	// this works in IE
	getAjax('/search.json', function(data){
	    var response = JSON.parse(data);
	    //console.log(response); 
	    searchIndex = response.search;
	});

	// this does not work in IE
	/*
	fetch('/search.json').then(function(response) {
		return response.json();
	}).then(function(response) {
		searchIndex = response.search;
	});
	*/
	
	
	// listen for input changes
	searchInput.addEventListener('keyup', function(event) {
		var str = searchInput.value;
		
		if(str.length > 0) {
			addClass(searchIcon, 'd-none');
			removeClass(clearBtn, 'd-none');
		} else {
			removeClass(searchIcon, 'd-none');
			addClass(clearBtn, 'd-none');
		}
		
		if(str.length > 2) {
			find(str);
		} else {
			clearResults();
		}
	});
	
	clearBtn.addEventListener('click', function(event) {
		clearResults();
		clearSearchInput();
		searchInput.focus();
	});
	
	// hide results if focus is removed from the search field
	window.addEventListener('click',function(event){
		var searchUI = document.querySelector('.search-ui');
		if(event.target != searchUI && !searchUI.contains(event.target)){
			addClass(resultsUI, 'invisible');
		}
	});  
	
	// show results if focus is returned to the field
	searchInput.addEventListener('mousedown', function(event) {
		var str = searchInput.value;
		if(str.length > 2) {
			removeClass(resultsUI, 'invisible');
		}
		
	});


})();