var forms = document.getElementsByClassName('needs-validation');
// Loop over forms
var validation = Array.prototype.filter.call(forms, function(form) {
  form.addEventListener('submit', function(event) {
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
  }, false);
});

var radios = document.querySelectorAll('input[type="radio"]');
for (var i = 0; i < radios.length; i++) {
	if( radios[i].getAttribute('data-other') ) {
		radios[i].addEventListener('click', function(event) {
			checkOther(this);
		}, false);
	}
}

var checkOther = function(item) {
	
	var other = document.getElementById(item.getAttribute('data-other'));
	
	if (hasClass(item, 'otherRadio')) {
		other.disabled = false;
		other.required = true;
	} else {
		other.disabled = true;
		other.required = false;
	}
}

// must check at least one checkbox for "I am…" (purpose) 
var purposeCheckboxes = document.querySelectorAll('.purpose input[type="checkbox"]');
for (var i = 0; i < purposeCheckboxes.length; i++) {
	purposeCheckboxes[i].addEventListener('click', function(event) {
		checkPurpose();
	}, false);
}

var checkPurpose = function() {
	var checkedBoxes = document.querySelectorAll('.purpose input[type="checkbox"]:checked');
	if(checkedBoxes.length > 0) {
		for (var i = 0; i < purposeCheckboxes.length; i++) {
			purposeCheckboxes[i].removeAttribute('required');
		}
	} else {
		for (var i = 0; i < purposeCheckboxes.length; i++) {
			purposeCheckboxes[i].setAttribute('required', 'required');
		}
	}
}

// if "other" is selected for purpose, make the text field required
var otherPurposeCheckbox = document.querySelectorAll('[data-other="otherPurpose"]')[0];
var otherPurpose = document.getElementById('otherPurpose');
if (otherPurposeCheckbox) {
	otherPurposeCheckbox.addEventListener('click', function(event) {
		if(this.checked) {
			otherPurpose.disabled = false;
			otherPurpose.required = true;
		} else {
			otherPurpose.disabled = true;
			otherPurpose.required = false;
		} 
	}, false);
}
